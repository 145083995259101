export enum NeoTrackEventAction {
  View = 'View',
  Click = 'Click',
  Drag = 'Drag',
  Drop = 'Drop',
  Undo = 'Undo',
  Duration = 'Duration',
}

export enum NeoTrackEventStatus {
  None = '',
  Pending = 'Pending',
  Success = 'Success',
  Fail = 'Fail',
  Cancel = 'Cancel',
  NA = 'NA',
}
export class NeoTrackEventComponent {
  public static readonly App = 'App';

  public static readonly CalendarNotificationButton = 'NotificationButton';
  public static readonly CalendarLockDayButton = 'LockDayButton';
  public static readonly CalendarUnlockDayButton = 'UnlockDayButton';
  public static readonly CalendarAddProjectModal = 'AddProjectModal';
  public static readonly CalendarAddProjectModalButton = 'AddProjectModalButton';
  public static readonly CalendarAddProjectModalSaveButton = 'AddProjectModalSaveButton';
  public static readonly CalendarAddProjectModalCloseButton = 'AddProjectModalCloseButton';
  public static readonly CalendarAddProjectButton = 'AddProjectButton';
  public static readonly CalendarAddProjectCancelButton = 'AddProjectCancelButton';
  public static readonly CalendarEditProjectModal = 'EditProjectModal';
  public static readonly CalendarEditProjectModalButton = 'EditProjectModalButton';
  public static readonly CalendarEditProjectModalSaveButton = 'EditProjectModalSaveButton';
  public static readonly CalendarEditProjectModalCloseButton = 'EditProjectModalCloseButton';
  public static readonly CalendarEditProjectButton = 'EditProjectButton';
  public static readonly CalendarEditProjectCancelButton = 'EditProjectCancelButton';
  public static readonly CalendarRepeatProjectModal = 'RepeatProjectModal';
  public static readonly CalendarRepeatProjectModalButton = 'RepeatProjectModalButton';
  public static readonly CalendarRepeatProjectModalSaveButton = 'RepeatProjectModalSaveButton';
  public static readonly CalendarRepeatProjectModalCloseButton = 'RepeatProjectModalCloseButton';
  public static readonly CalendarRepeatProjectButton = 'RepeatProjectButton';
  public static readonly CalendarRepeatProjectCancelButton = 'RepeatProjectCancelButton';
  public static readonly CalendarRepeatDayModal = 'RepeatDayModal';
  public static readonly CalendarRepeatDayModalButton = 'RepeatDayModalButton';
  public static readonly CalendarRepeatDayModalValidateButton = 'RepeatDayModalValidateButton';
  public static readonly CalendarRepeatDayModalSaveButton = 'RepeatDayModalSaveButton';
  public static readonly CalendarRepeatDayModalCloseButton = 'RepeatDayModalCloseButton';
  public static readonly CalendarRepeatDayButton = 'RepeatDayButton';
  public static readonly CalendarRepeatDayCancelButton = 'RepeatDayCancelButton';
  public static readonly CalendarBooking = 'Booking';
  public static readonly CalendarBookingInfoButton = 'BookingInfoButton';
  public static readonly CalendarBookingStatusButton = 'BookingStatusButton';
  public static readonly CalendarBookingStatusUndoButton = 'BookingStatusUndoButton';

  public static readonly StartTime = 'StartTime';
  public static readonly SmsTemplate = 'SmsTemplate';

  public static readonly ScheduledWorks = 'ScheduledWorks';
  public static readonly ScheduledWorksAddButton = 'ScheduledWorksAddButton';
  public static readonly ScheduledWorksRemoveButton = 'ScheduledWorksRemoveButton';

  public static readonly AssignedEquipment = 'AssignedEquipment';
  public static readonly AssignedEquipmentSelect = 'AssignedEquipmentSelect';

  public static readonly AssignedEquipmentRemove = 'AssignedEquipmentRemove';

  public static readonly AssignedStaff = 'AssignedStaff';
  public static readonly AssignedStaffSelect = 'AssignedStaffSelect';

  public static readonly AssignedStaffRemove = 'AssignedStaffRemove';


}

export class NeoTrackEventLocation {
  public static readonly Login = 'Login';
  public static readonly Logout = 'Logout';
  public static readonly AddProject = 'AddProject';
  public static readonly EditProject = 'EditProject';
  public static readonly Calendar = 'Calendar';


  public static readonly AuthCheck = 'AuthCheck';
  public static readonly PageNotFound = 'PageNotFound';
  public static readonly PageFailed = 'PageFailed';
}

